<template>
  <div class="ficheConfig">
    <div class="box-setting-header ">
      <div class="title">Gestion des Fiches</div>
    </div>
    <div class="ligne-box-setting-header"></div>
    <div class="tableFiche mr-2 mb-2">
      <b-table-simple v-if="this.getFicheStandard" class="table-fiche">
        <b-thead>
          <b-tr>
            <b-th colspan="3" class="titleFiche"
              >Fiche standard : Type chauffage</b-th
            >
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr class="title-ligne">
            <b-th class="newcolor" colspan="1"></b-th>
            <b-th class="newcolor" colspan="1">Electrique</b-th>
            <b-th class="newcolor" colspan="1">Combustible</b-th>
          </b-tr>
          <b-tr>
            <b-td class="title">H1</b-td>
            <b-td>
              <EditableInput
                :editable="checkPermission('GFTHMO')"
                champName="h1Electrique"
                :item="this.getFicheStandard"
                :value="this.getFicheStandard.h1Electrique"
                type="text"
                :defaultColor="true"
                :updateFunction="editFicheStandard"
                className="editable-input-fiche"
              />
            </b-td>
            <b-td>
              <EditableInput
                :editable="checkPermission('GFTHMO')"
                champName="h1Combustible"
                :item="this.getFicheStandard"
                :value="this.getFicheStandard.h1Combustible"
                type="text"
                :defaultColor="true"
                :updateFunction="editFicheStandard"
                className="editable-input-fiche"
              />
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="title">H2</b-td>
            <b-td>
              <EditableInput
                :editable="checkPermission('GFTHMO')"
                champName="h2Electrique"
                :item="this.getFicheStandard"
                :value="this.getFicheStandard.h2Electrique"
                type="text"
                :defaultColor="true"
                :updateFunction="editFicheStandard"
                className="editable-input-fiche"
              />
            </b-td>
            <b-td>
              <EditableInput
                :editable="checkPermission('GFTHMO')"
                champName="h2Combustible"
                :item="this.getFicheStandard"
                :value="this.getFicheStandard.h2Combustible"
                type="text"
                :defaultColor="true"
                :updateFunction="editFicheStandard"
                className="editable-input-fiche"
              />
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="title">H3</b-td>
            <b-td>
              <EditableInput
                :editable="checkPermission('GFTHMO')"
                champName="h3Electrique"
                :item="this.getFicheStandard"
                :value="this.getFicheStandard.h3Electrique"
                type="text"
                :defaultColor="true"
                :updateFunction="editFicheStandard"
                className="editable-input-fiche"
              />
            </b-td>
            <b-td>
              <EditableInput
                :editable="checkPermission('GFTHMO')"
                champName="h3Combustible"
                :item="this.getFicheStandard"
                :value="this.getFicheStandard.h3Combustible"
                type="text"
                :defaultColor="true"
                :updateFunction="editFicheStandard"
                className="editable-input-fiche"
              />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div class="tableFiche">
      <b-table-simple v-if="this.getFichePouce" class="table-fiche">
        <b-thead>
          <b-tr
            ><b-th colspan="3" class="titleFiche"
              >Fiche coup de pouce</b-th
            ></b-tr
          >
        </b-thead>
        <b-tbody>
          <b-tr class="title-ligne">
            <b-th class="newcolor" colspan="1">Classique</b-th>
            <b-th class="newcolor" colspan="1">simple ou grand</b-th>
          </b-tr>
          <b-tr>
            <b-td>
              <EditableInput
                :editable="checkPermission('GFTHMO')"
                champName="Classique"
                :item="this.getFichePouce"
                :value="this.getFichePouce.Classique"
                type="text"
                :defaultColor="true"
                :updateFunction="editFichePouce"
                className="editable-input-fiche"
              />
            </b-td>
            <b-td>
              <EditableInput
                :editable="checkPermission('GFTHMO')"
                champName="simple_ou_grand"
                :item="this.getFichePouce"
                :value="this.getFichePouce.simple_ou_grand"
                type="text"
                :defaultColor="true"
                :updateFunction="editFichePouce"
                className="editable-input-fiche"
              />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  methods: {
    ...mapActions([
      'fetchFicheStandard',
      'fetchFichePouce',
      'editFicheStandard',
      'editFichePouce'
    ])
  },
  computed: {
    ...mapGetters(['getFicheStandard', 'getFichePouce', 'checkPermission'])
  },
  components: {
    EditableInput: () => import('../component/EditableInput.vue')
  },
  mounted() {
    this.fetchFicheStandard();
    this.fetchFichePouce();
  }
};
</script>
<style lang="scss" scoped>
.ficheConfig {
  display: block;
  padding: 10px;
  margin: 0px;
  min-height: 100%;
  width: 100%;
  overflow-y: auto;
  .tableFiche {
    display: inline;
  }
}
</style>
